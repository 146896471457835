export default {
  data() {
    return {
      parkingFeeInfo: 'A $4 parking fee is included in an effort to help our professionals with their arrivals and parking fees (to avoid this charge please provide free on-site available parking instructions)'
    }
  },
  computed: {
    parkingFeeTooltip() {
      return this.parkingFeeInfo
    },
    parkingFeeTooltipWithPropertyLink() {
      if (this.propertyLink == null) {
        console.error('To use infos_mixin#parkingFeeTooltipWithPropertyLink you need to define propertyLink')
      }
      return this.parkingFeeInfo + ' <a href="' + this.propertyLink + '">Go to property</a>'
    }
  }
}
